
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          

























































































































.end-slide {
  display: grid;
  grid-template: 1fr 1fr / 1fr;
  padding: 9.6rem 1.6rem 1.6rem 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    @include fontSize(3.2rem, 3.2, 3.2rem, 1.4);
    text-transform: uppercase;
    font-weight: 600;
  }

  .cashback {
    &__button {
      width: 90%;
      padding: 1.4rem 2.4rem;
      margin: 3.6rem auto;
      display: block;
      border-radius: 5.2rem;
      font-weight: 600;
      border: 1px solid #0d4058;
      color: #0d4058;
      text-decoration: none;
      transition: all 0.1s linear;

      &:hover {
        background: #0d4058;
        color: $color-text--inverse;
      }
    }

    .announcement {
      background: $color-white;
      border-radius: 1.4rem;
      text-align: left;
      padding: 2.4rem;
      transition: all $basic-transition-200;
      word-break: break-all;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4.2rem;

      button {
        margin: 0 auto;
        box-shadow: inset 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
        background: #ceeab8;

        &:hover {
          box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
        }

        &::after {
          border-radius: inherit;
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          transition: all 0.1s ease-out;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  .socials {
    margin-bottom: 12rem;

    img {
      width: 100%;
      object-fit: cover;
    }

    .share {
      p {
        margin-bottom: 4.2rem;
      }

      &__list {
        display: flex;
        justify-content: center;
        align-items: center;

        button ~ button {
          margin-left: 1.8rem;
        }
      }
    }
  }

  @include for-tablet-landscape-up {
    padding: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: rs(32) !important;
    }

    .cashback {
      max-width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__button {
        width: auto;
        padding: rs(14) rs(24);
        margin: rs(36) auto;
        border-radius: rs(52);
        border: rs(1) solid #0d4058;
      }

      .announcement {
        max-width: 80%;
        border-radius: rs(14);
        padding: rs(36) rs(24);
      }

      .actions {
        margin-top: rs(42);

        button {
          box-shadow: inset 0 rs(10) rs(30) 0 rgba(0, 0, 0, 0.1);
        }
      }
    }

    .socials {
      margin-bottom: unset;
      margin-top: rs(72);

      img {
        position: fixed;
        display: flex;
        width: rs(400);
        right: rs(10);
        bottom: rs(20);
      }

      .share {
        p {
          margin-bottom: rs(42);
        }

        &__list {
          button ~ button {
            margin-left: rs(18);
          }
        }
      }
    }
  }
}
