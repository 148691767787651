.ui {
  height: 100vh;
  width: 100vw;

  &__element {
    position: fixed;

    &--center {
      top: 50%;
      right: unset;
      left: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
    }

    &--top-right {
      top: $ui-padding-mobile;
      right: $ui-padding-mobile;
      left: unset;
      bottom: unset;

      @include for-tablet-landscape-up {
        top: $ui-padding;
        right: $ui-padding;
      }

      @include for-giant-desktop-up {
        top: rs(24);
        right: rs(24);
      }
    }

    &--top-left {
      top: $ui-padding-mobile;
      right: unset;
      left: $ui-padding-mobile;
      bottom: unset;

      @include for-tablet-landscape-up {
        top: $ui-padding;
        left: $ui-padding;
      }

      @include for-giant-desktop-up {
        top: rs(24);
        left: rs(24);
      }
    }

    &--top-center {
      top: $ui-padding-mobile;
      right: unset;
      left: 50%;
      bottom: unset;
      transform: translateX(-50%);

      @include for-tablet-landscape-up {
        top: $ui-padding;
      }

      @include for-giant-desktop-up {
        top: rs(24);
      }
    }

    &--bottom-right {
      top: unset;
      right: $ui-padding-mobile;
      left: unset;
      bottom: $ui-padding-mobile;

      @include for-tablet-landscape-up {
        right: $ui-padding;
        bottom: $ui-padding;
      }

      @include for-giant-desktop-up {
        bottom: rs(24);
        right: rs(24);
      }
    }

    &--bottom-left {
      top: unset;
      right: unset;
      left: $ui-padding-mobile;
      bottom: $ui-padding-mobile;

      @include for-tablet-landscape-up {
        left: $ui-padding;
        bottom: $ui-padding;
      }

      @include for-giant-desktop-up {
        left: rs(24);
        bottom: rs(24);
      }
    }

    &--bottom-center {
      top: unset;
      right: unset;
      left: 50%;
      bottom: $ui-padding-mobile;
      transform: translateX(-50%);

      @include for-tablet-landscape-up {
        bottom: $ui-padding;
      }

      @include for-giant-desktop-up {
        bottom: rs(24);
      }
    }

    &--koi-score {
      bottom: 2.4rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__koi {
    &__timer {
      top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 2.4rem;

      @include for-tablet-landscape-up {
        top: 2.4rem;
      }

      p {
        @include fontSize(3.6rem, 5.2, 5.2rem, 1);
        color: $color-text--inverse;
        font-weight: bold;

        &.pulsing {
          // color: darken($color-orange--primary, 10%);
          animation: pulse 1s infinite cubic-bezier(0.86, 0, 0.07, 1);
        }
      }

      button {
        width: 100%;
      }

      @keyframes pulse {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    &__score {
      @include scaleWidth(8);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .img__wrapper {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all $basic-transition-300;

          &.img--animate {
            transform: rotate(10deg) scale(1.1);
          }
        }
      }

      p {
        // position: relative;
        font-weight: bold;
        @include fontSize(3.6rem, 3.6, 3.6rem);
        color: $color-text--inverse;
      }
    }

    &__overlay {
      top: 0;
      z-index: 2;
      height: 16rem;
      pointer-events: none;
      width: 100%;
      opacity: 0.4;
      position: fixed;
      background: linear-gradient(180deg, #000000 0%, rgba(#000000, 0) 65%);
    }

    &__overlay--multiply-blue {
      z-index: 2 !important;
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(#0092e0, 0.8);
      mix-blend-mode: multiply;
    }
  }

  #hit {
    position: absolute;
    @include fontSize(3.6rem, 3.6, 3.6rem);
    font-weight: bold;
    color: $color-yellow--primary;
    pointer-events: none;

    transform: translate(0%, -50%);
    opacity: 0;
    transition: all $bounce-transition-500;

    &.hit--animate {
      opacity: 1;
      transform: translate(0%, -50%) scale(5) rotate(10deg);
    }
  }

  &__actions {
    padding-top: 0.5rem;

    @include for-tablet-landscape-up {
      padding: unset;
    }
  }

  @include for-giant-desktop-up {
    &__koi {
      &__timer {
        top: rs(32);
        grid-gap: rs(24);

        p {
          font-size: rs(52) !important;
        }
      }

      &__score {
        width: rs(80);

        p {
          font-size: rs(36) !important;
        }
      }

      &__overlay {
        height: rs(160);
      }
    }

    #hit {
      font-size: rs(36) !important;
    }

    &__actions {
      padding-top: rs(5);
    }
  }
}

.screen,
.prompt {
  display: grid;
  place-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}

.screen {
  overflow: hidden !important;
}

.logo__wrapper,
.logo__wrapper--home {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.logo__wrapper--home {
  top: -0.2rem;
  left: 0;

  .logo {
    &--ol {
      @include scaleWidth(10);

      @include for-tablet-landscape-up {
        @include scaleWidth(25);
      }

      @include for-giant-desktop-up {
        width: rs(250);
      }
    }
  }

  @include for-tablet-landscape-up {
    top: -2.4rem;
    left: -1.2rem;
  }

  @include for-giant-desktop-up {
    top: rs(-24);
    left: rs(-12);
  }

  &.in-game {
    top: -0.2rem;
    left: 0;

    .logo {
      &--ol {
        @include scaleWidth(10);

        @include for-tablet-landscape-up {
          @include scaleWidth(25);
        }

        @include for-giant-desktop-up {
          width: rs(250);
        }
      }
    }

    @include for-tablet-landscape-up {
      top: -2.4rem;
      left: -1.2rem;
    }

    @include for-giant-desktop-up {
      top: rs(-24);
      left: rs(-12);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $basic-transition-500;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all $basic-transition-300;
}

.slide-down-enter {
  opacity: 0;

  &:not(.ui__koi__overlay) {
    transform: translate(-50%, -100%);
  }

  .ui__koi__overlay {
    transform: translateY(-100%);
  }
}

.slide-down-leave-to {
  opacity: 0;

  &:not(.ui__koi__overlay) {
    transform: translate(-50%, -100%);
  }

  .ui__koi__overlay {
    transform: translateY(-100%);
  }
}

.slide-bottom-enter-active,
.slide-bottom-leave-active {
  transition: all $basic-transition-300;
  transform: translate(-50%, 0);
}

.slide-bottom-enter {
  opacity: 0;
  transform: translate(-50%, 10rem);
}

.slide-bottom-leave-to {
  opacity: 0;
  transform: translate(-50%, 10rem);
}
