.screen__loading {
  background: linear-gradient(
    180deg,
    $color-green--primary 22.7%,
    rgba(darken($color-green--primary, 30%), 0.5) 100%
  );
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  flex-direction: column;
  grid-gap: 5vh;

  .logo {
    height: 100%;
    width: 100%;
    object-fit: cover;

    &__wrapper {
      width: 50%;

      @include for-tablet-landscape-up {
        width: 30%;
      }
    }
  }

  .progress {
    display: flex;
    justify-content: space-between;
    grid-gap: 2rem;
    margin: 2rem;
    flex-direction: column;

    @include for-tablet-portrait-up {
      flex-direction: row;
    }

    * {
      text-transform: uppercase;
      display: block;
      @include fontSize(2rem, 2.4, 2.4rem, 1);
      font-weight: 600;
      white-space: nowrap;
    }

    &__cat {
      max-width: 40%;

      rect[fill="#84ceff"] {
        display: none;
      }

      @include for-tablet-portrait-up {
        @include scaleWidth(20);
      }
    }

    &__percentage {
      order: 1;
      font-weight: normal;

      @include for-tablet-portrait-up {
        order: unset;
      }
    }

    &-text {
      order: 2;
      display: block;
      position: relative;

      * {
        letter-spacing: 0.2rem;
      }

      @include for-tablet-portrait-up {
        order: unset;
      }

      &__outer,
      &__inner {
        left: 0;
        position: absolute;
      }

      &__inner {
        width: 0%;
        overflow: hidden;
        transition: width $basic-transition-100;
      }

      &__outer {
        color: rgba($color-black, 0.4);
      }
    }

    &-bar {
      width: 100%;
      height: 1rem;
      @include scaleHeight(1);
      position: fixed;
      bottom: 0;
      background: rgba($color-black, 0.4);

      &__inner {
        position: relative;
        transform: translateX(-100%);
        height: 100%;
        width: 100%;
        background: $color-black;
        transition: left 0.1s linear;
      }
    }
  }

  @include for-giant-desktop-up {
    .progress {
      grid-gap: rs(20);
      margin: rs(20);

      * {
        font-size: rs(24) !important;
      }
    }
  }
}
