///// Scaling

@mixin scaleWidth($size, $total: $ui-max-width) {
  max-width: #{$size}rem;

  @include for-giant-desktop-up {
    $desired-max-width: #{$size * 6.6};
    max-width: unset;
    min-width: calc(#{$desired-max-width}vw * 100 / #{$total});
  }
}

@mixin scaleHeight($size, $total: $ui-max-width) {
  max-height: #{$size}rem;

  @include for-giant-desktop-up {
    $desired-max-height: #{$size * 6.6};
    max-height: unset;
    min-height: calc(#{$desired-max-height}vw * 100 / #{$total});
  }
}

// Reset scaling
@function rs($size) {
  @return calc(#{$size}vw * 100 / 1440);
}
