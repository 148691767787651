
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          









































.prompt__end {
  background: transparent;
  display: grid;
  place-content: center;

  background: linear-gradient(
    180deg,
    rgba(darken($color-purple--primary, 30%), 0.5) 22.7%,
    rgba(darken($color-purple--primary, 30%), 0.3) 100%
  );

  p.prompt__text {
    color: $color-text--inverse;
    width: 80%;
    max-width: 80%;
    position: absolute;
    font-weight: bold;
    @include fontSize(3.2rem, 3.2, 3.2rem, 1.1);

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.slide-enter-active,
    &.slide-leave-active {
      transition: all $basic-transition-1000;
    }

    &.slide-enter,
    &.slide-leave-to {
      opacity: 0;
    }

    &.slide-enter {
      transform: translate(-50%, 0%);
    }

    &.slide-leave-to {
      transform: translate(-50%, -500%);
    }
  }

  @include for-tablet-landscape-up {
    p.prompt__text {
      width: 65%;
      max-width: 65%;
      @include fontSize(6.2rem, 6.2, 6.2rem, 1);
    }
  }

  @include for-giant-desktop-up {
    p.prompt__text {
      font-size: rs(62) !important;
    }
  }
}
