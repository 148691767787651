
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          






























































































































































































































































































































#app {
  overflow: hidden;
}

#canvas {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  background: $color-green--primary;
  opacity: 0;
  transition: all $basic-transition-200;

  &.game--animate {
    opacity: 1;
  }
}

// Layer setup
.cookie-banner {
  z-index: 12;
}

.ui__element {
  z-index: 11;
}

.screen__rotate {
  z-index: 10 !important;
}

.ui__koi {
  z-index: 9;
}

.screen,
.prompt {
  z-index: 9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// Temp
.temp {
  position: fixed;
  z-index: 1000;
  flex-direction: column;
  display: flex;
  bottom: 0;
  right: 0;

  @include for-tablet-landscape-up {
    display: flex;
  }
}

.temp-button {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10000;
}

.lil-gui,
#statsContainer {
  opacity: 0.6;
}

.slide-cookie-down-enter-active,
.slide-cookie-down-leave-active {
  transition: transform 0.3s ease-in;
  z-index: 12;
}

.slide-cookie-down-enter,
.slide-cookie-down-leave-to {
  transform: translateY(100%);
  z-index: 12;
}
