
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          


































.check-input {
  margin: 4.6rem 0 0 0 !important;

  * {
    transition: all $basic-transition-200, box-shadow 0s linear;
  }

  label * {
    margin: 0;
    font-weight: 600;
    @include fontSize(1.4rem, 1.4, 1.4rem);
    color: $color-text--inverse;

    a {
      color: $color-blue--secondary;

      &:hover {
        color: $color-blue--primary;
      }
    }
  }

  /* The container */
  .container {
    text-align: left;
    display: block;
    position: relative;
    padding-left: 3rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    @include fontSize(2.2rem, 2.2, 2.2rem);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.8rem;
    width: 1.8rem;
    background-color: $color-white;
    border-radius: 0.4rem;
    top: 50%;
    transform: translateY(-50%);

    &.invalid {
      box-shadow: 0 0 0 0.3rem $color-text--invalid;
    }
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: $color-blue--tertiary;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: $color-white;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 0.5rem;
    top: 0.1rem;
    width: 0.4rem;
    height: 0.9rem;
    border: solid $color-black;
    border-width: 0 0.4rem 0.4rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  @include for-giant-desktop-up {
    margin: rs(46) 0 0 0;

    label * {
      font-size: rs(14) !important;
    }

    .container {
      padding-left: rs(30);
      margin-bottom: rs(12);
      font-size: rs(22) !important;
    }

    .checkmark {
      height: rs(18);
      width: rs(18);
      border-radius: rs(4);

      &.invalid {
        box-shadow: 0 0 0 rs(3) $color-text--invalid;
      }
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: rs(5);
      top: rs(1);
      width: rs(4);
      height: rs(9);
      border-width: 0 rs(4) rs(4) 0;
    }
  }
}
