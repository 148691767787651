
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          







































































.number {
  font-weight: 600;

  &__row {
    display: flex;
    align-items: center;
    flex: 1;

    @include for-tablet-landscape-up {
      flex: unset;
    }
  }

  &:not(.active) {
    width: 0;
    display: grid;
    place-content: center;
    position: relative;

    &.after {
      opacity: 0.5;
    }
  }

  &.active {
    display: grid;
    place-content: center;
    background-image: url("/assets/img/diamond.svg");
    background-size: cover;
    height: 5rem;
    width: 5rem;
    position: relative;
    background-repeat: no-repeat;

    &::after {
      content: "";
      position: absolute;
      bottom: -2.4rem;
      background-image: url("/assets/img/diamond--shadow.png");
      width: 150%;
      height: 1.4rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include for-giant-desktop-up {
    &.active {
      height: rs(50);
      width: rs(50);

      &::after {
        bottom: rs(-24);
        height: rs(14);
      }
    }
  }
}
