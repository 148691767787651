
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          



















































































































































.end-slide {
  display: grid;
  grid-template: 2fr 3fr / 1fr;
  padding: 9.6rem 1.6rem 1.6rem 1.6rem;

  h2 {
    @include fontSize(4.8rem, 4.8, 4.8rem, 1);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 2.4rem;
  }

  h3 {
    @include fontSize(1.8rem, 1.8, 1.8rem, 1.5);
    font-weight: normal;
  }

  .score {
    .finished {
      &__card {
        background: $color-white;
        padding: 2rem;
        display: grid;
        grid-template: 1fr / 1fr 1fr;
        grid-gap: 4.2rem;
        min-height: 16rem;
        border-radius: 1.2rem;
        text-align: center;
        position: relative;

        &::after {
          content: "";
          background-image: url("/assets/img/congratulations--shadow.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 150%;
          position: absolute;
          transform: translate(-50%, 100%);
          top: 100%;
          left: 50%;
          height: 2.8rem;
        }

        h3 {
          margin: 0 auto;

          &.green {
            color: $color-green--primary;
          }

          span {
            color: $color-text;
          }
        }

        * {
          @include fontSize(1.6rem, 1.6, 1.8rem);
          text-transform: uppercase;
          font-weight: 600;
        }

        span {
          display: block;
          @include fontSize(7.2rem, 8.8, 8.8rem, 1.25);
          letter-spacing: -0.65rem;
        }

        .divider {
          position: absolute;
          height: 80%;
          width: 0.2rem;
          background: $color-blue--tertiary;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .star {
          // #FEDE00
          filter: invert(77%) sepia(78%) saturate(838%) hue-rotate(357deg)
            brightness(101%) contrast(109%)
            drop-shadow(0 0 1.6rem rgba($color-black, 0.3));
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 100%) rotate(0) scale(0.5);
          transition: all $basic-transition-500;
          animation: particle var(--duration) linear infinite var(--delay);

          @keyframes particle {
            form {
              transform: translate(-50%, 0%) rotate(0) scale(0.5);
              opacity: 1;
            }
            to {
              transform: translate(var(--x), -300%) rotate(var(--angle))
                scale(var(--scale));
              opacity: 0;
            }
          }

          &__animation {
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all $basic-transition-500;
          }
        }
      }
    }
  }

  .product {
    position: relative;

    .swiper {
      margin-top: 2.4rem;
      width: 100%;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        * {
          pointer-events: none !important;
          user-select: none;
        }

        img {
          width: 50%;
        }

        p {
          text-transform: uppercase;
          @include fontSize(1.8rem, 1.8, 1.8rem, 1.25);
          font-weight: 600;
          width: 75%;
          margin-top: 3.2rem;
        }

        .img__wrapper {
          max-width: 15rem;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

  @include for-tablet-landscape-up {
    padding: unset;
    grid-template: 1fr / 1fr;
    place-items: center;

    h2 {
      font-size: rs(48) !important;
      margin-bottom: rs(46);
    }

    .score {
      position: relative;
      transform: translateX(-33%);

      .finished {
        &__card {
          background: $color-white;
          padding: rs(48);
          display: grid;
          grid-template: 1fr / 1fr 1fr;
          grid-gap: rs(96);
          min-height: rs(160);
          border-radius: rs(12);
          text-align: center;
          position: relative;

          &::after {
            height: rs(28);
          }

          * {
            font-size: rs(16) !important;
          }

          span {
            letter-spacing: rs(-6.5);
            font-size: rs(72) !important;
          }

          .divider {
            width: rs(2);
          }

          .star__animation {
            display: none;
          }
        }
      }
    }

    .product {
      position: absolute;
      display: grid;
      place-items: center;
      right: rs(0);
      bottom: rs(36);

      .swiper {
        width: rs(350);
        margin-top: unset;
        position: relative;

        .swiper-slide {
          p {
            font-size: rs(26) !important;
            margin-top: rs(32);
          }

          .img__wrapper {
            width: rs(180);
            max-width: unset;
          }
        }
      }
    }
  }
}
