///// Text variables

// Colors
$color-text: #273841;
$color-text--inverse: #ffffff;
$color-text--valid: #00b309;
$color-text--invalid: #f10505;

$color-black: #273841;
$color-black--lighter: #2a3b43;
$color-black--darker: #060f14;
$color-white: #ffffff;
$color-grey: #c0c0c0;

// Fonts
$font-family-title: "Avenir", sans-serif;
$font-family-text: "Avenir", sans-serif;

// Font sizes
$title-size-mobile: 2.9;
$title-size-tablet-portrait: 3.6;
$title-size-tablet-landscape: 4.2;
$title-size-desktop: 4.8;

$subtitle-size-mobile: 2;
$subtitle-size-tablet-portrait: 2.2;
$subtitle-size-tablet-landscape: 2.5;
$subtitle-size-desktop: 2.5;

$text-size-mobile: 1.6;
$text-size-tablet-portrait: 1.6;
$text-size-tablet-landscape: 1.8;
$text-size-desktop: 1.8;

///// Ui variables

// Ui sizes
$ui-padding: 2.4rem;
$ui-padding-mobile: 0.6rem;
$ui-max-width: 1440;

// Colors
$color-green--primary: #aec999;
$color-green--primary-darker: #517c42;
$color-green--secondary: #d2e8c1;
$color-green--tertiary: #c3d8b2;

$color-orange--primary: #f6a372;
$color-orange--secondary: #ffd5bc;
$color-orange--tertiary: #ffd5bc;

$color-purple--primary: #bca0d1;
$color-purple--secondary: #e4d2f1;
$color-purple--tertiary: #e4d2f1;

$color-yellow--primary: #f0c266;

$color-blue--primary: #abcfdc;
$color-blue--secondary: #146f90;
$color-blue--tertiary: #e9f1f5;

///// Animation variables
$basic-transition-100: 100ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-200: 200ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-300: 300ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-500: 500ms cubic-bezier(0.86, 0, 0.07, 1);
$basic-transition-1000: 1s cubic-bezier(0.86, 0, 0.07, 1);

$bounce-transition-300: 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
$bounce-transition-500: 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
