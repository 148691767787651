/////// Avenir

@font-face {
  font-family: "Avenir";
  src: url("/assets/font/Avenir LT Std 95 Black.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Avenir";
  src: url("/assets/font/AvenirLTProHeavy.woff2") format("woff2"),
    url("/assets/font/AvenirLTProHeavy.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "Avenir";
  src: url("/assets/font/Avenir LT Std 65 Medium.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Avenir";
  src: url("/assets/font/AvenirLTProLight.woff2") format("woff2"),
    url("/assets/font/AvenirLTProLight.woff") format("woff");
  font-weight: lighter;
}

/////// Avenir LT

// @font-face {
//   font-family: "AvenirLT";
//   src: url("/assets/font/Avenir LT Std 95 Black.otf");
//   font-weight: 600;
// }

// @font-face {
//   font-family: "AvenirLT";
//   src: url("/assets/font/Avenir LT Std 85 Heavy.otf");
//   font-weight: bold;
// }

// @font-face {
//   font-family: "AvenirLT";
//   src: url("/assets/font/Avenir LT Std 65 Medium.otf");
//   font-weight: normal;
// }

// @font-face {
//   font-family: "AvenirLT";
//   src: url("/assets/font/Avenir LT Std 35 Light.otf");
//   font-weight: lighter;
// }

///// Font mixin

@mixin fontSize(
  $min-size,
  $size,
  $max-size,
  $line-height: 1.5,
  $total: $ui-max-width
) {
  font-size: clamp(#{$min-size}, #{$size}vw, #{$max-size}) !important;
  line-height: #{$line-height};

  @include for-giant-desktop-up {
    font-size: rs($size) !important;
  }
}

///// Font

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  @include fontSize(1.4rem, 1.6, 1.8rem, 1.1);
  font-family: $font-family-text;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: $font-family-text;
  }

  @include for-giant-desktop-up {
    font-size: rs(18) !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-title;
  font-weight: 600;
}

p,
blockquote,
a,
span {
  font-family: $font-family-text;
  font-weight: normal;
}

h1,
h1 span {
  font-weight: bold;

  @include fontSize(
    #{$title-size-mobile}rem,
    $title-size-desktop,
    #{$title-size-desktop}rem
  );

  @include for-giant-desktop-up {
    font-size: rs(#{$title-size-desktop} * 10) !important;
  }
}

h2,
h2 span {
  font-weight: bold;

  @include fontSize(
    #{$subtitle-size-mobile}rem,
    $subtitle-size-desktop,
    #{$subtitle-size-desktop}rem
  );

  @include for-giant-desktop-up {
    font-size: rs(#{$subtitle-size-desktop} * 10) !important;
  }
}

p,
p span,
a,
a span {
  font-weight: normal;

  @include fontSize(
    #{$text-size-mobile}rem,
    $text-size-desktop,
    #{$text-size-desktop}rem
  );

  @include for-giant-desktop-up {
    font-size: rs(#{$text-size-desktop} * 10) !important;
  }
}
