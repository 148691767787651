
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          



















































.select-input {
  --vs-actions-padding: 0 0.5rem 0 0;
  margin: 2rem 0 0 0 !important;

  label {
    margin: 0;
    font-weight: 600;
    @include fontSize(1.2rem, 1.2, 1.2rem);
    color: $color-text--inverse;
    text-transform: uppercase;
  }

  .select-wrapper {
    width: 100%;
    margin-top: 0.7rem;

    &.invalid .vs__dropdown-toggle {
      box-shadow: 0 0 0 0.3rem $color-text--invalid;
    }

    * {
      color: $color-text;
      font-weight: normal;
    }

    .vs__dropdown-toggle {
      min-height: 5rem;
      min-width: 30rem;
      background: $color-white;
      border-radius: 0.8rem;
      border: none;
      padding: 0;
      transition: all 0.1s linear, box-shadow 0s linear;
    }

    .vs__search {
      padding: 0 0 0 1rem;

      &::placeholder {
        @include fontSize(1.8rem, 1.8, 1.8rem);
        opacity: 0.3;
      }
    }

    .vs__dropdown-option {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
    }

    .vs__dropdown-menu {
      margin-top: 1rem;
      border-radius: 0.8rem;
    }

    .vs__open-indicator {
      // #C0C0C0
      filter: invert(76%) sepia(53%) saturate(0%) hue-rotate(172deg)
        brightness(93%) contrast(90%);
    }

    .vs__selected {
      height: 100%;
      width: 100%;
      padding: 0 0 0 1rem;
      margin: 0;
      display: flex;
      align-items: center;
    }

    img.flag {
      max-width: 2rem;
      margin-right: 1.6rem;
    }
  }

  .error {
    font-weight: 600;
    @include fontSize(1.2rem, 1.2, 1.2rem);
    margin: 1rem 0 0 0;
    color: $color-text--invalid;
  }

  .slide-down-enter-active,
  .slide-down-leave-active {
    transition: all $basic-transition-300;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    opacity: 0;
    transform: translateY(-50%);
  }

  @include for-tablet-landscape-up {
    --vs-actions-padding: 0 rs(5) 0 0;
    margin: rs(20) 0 0 0 !important;

    label {
      font-size: rs(10) !important;
    }

    .select-wrapper {
      margin-top: rs(7);

      * {
        font-size: rs(16) !important;
      }

      .vs__open-indicator {
        width: rs(30);
      }

      &.invalid .vs__dropdown-toggle {
        box-shadow: 0 0 0 rs(3) $color-text--invalid;
      }

      .vs__dropdown-toggle {
        min-height: rs(45);
        min-width: rs(300);
        border-radius: rs(8);
      }

      .vs__search {
        padding: 0 0 0 rs(10);

        &::placeholder {
          font-size: rs(16) !important;
        }
      }

      .vs__dropdown-option {
        padding: rs(10) rs(20);
      }

      .vs__dropdown-menu {
        margin-top: rs(10);
        border-radius: rs(8);
      }

      .vs__selected {
        padding: 0 0 0 rs(10);
      }

      img.flag {
        max-width: rs(20);
        margin-right: rs(16);
      }
    }

    .error {
      font-size: rs(12) !important;
      margin: rs(10) 0 0 0;
    }
  }
}
