.screen__finished {
  background: $color-blue--primary;

  &::after {
    content: "";
    background-image: url("/assets/img/diamond--background-inverse.svg");
    background-repeat: no-repeat;
    background-position: 80% 50%;
    top: 50%;
    bottom: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    pointer-events: none;
    z-index: -2;
    transform: rotate(90deg) translate(-50%, 0);
  }

  @include for-tablet-landscape-up {
    background: linear-gradient(
      180deg,
      $color-blue--primary 22.7%,
      rgba(darken($color-blue--primary, 40%), 0.5) 100%
    );

    &::after {
      content: "";
      background-image: url("/assets/img/diamond--background-inverse.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 100% 150%;
      position: absolute;
      height: 135%;
      width: 105%;
      opacity: 0.2;
      pointer-events: none;
      z-index: -2;
      transform: unset;
      bottom: 0;
      top: unset;
    }
  }

  overflow: hidden;

  transition: all $basic-transition-1000 0.3s;

  .end-slide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all $basic-transition-500;
  }

  .slide-enter,
  .slide-leave-to {
    opacity: 0;
  }

  .slide-enter {
    transform: translate(0%, -50%);
  }

  .slide-leave-to {
    transform: translate(-100%, -50%);
  }
}
