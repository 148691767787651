
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          













































.cookie-banner {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: $color-white;
  padding: 5rem 3.2rem 3.2rem 3.2rem;
  display: flex;
  flex-direction: column;
  grid-gap: 2.4rem;
  align-items: center;
  justify-content: space-between;
  box-shadow: 4px 4px 12px 8px #0000001a;
  transform: translateY(0);

  &__wrapper {
    height: 100vh;
    position: relative;
  }

  // &__vet {
  //   position: fixed;
  //   bottom: 20%;
  //   z-index: 12;
  //   width: 30%;
  //   @include scaleWidth(15);
  // }

  &__cookies {
    width: 20rem;
    position: absolute;
    top: -4.8rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    display: flex;
    align-items: center;
  }

  * {
    @include fontSize(1.6rem, 1.6, 1.6rem, 1.25);
  }

  p {
    color: $color-text !important;
    text-align: center;
  }

  .button {
    width: auto;
  }

  @include for-tablet-landscape-up {
    flex-direction: row;
    padding: 2.4rem 5.2rem 2.4rem 28rem;
    grid-gap: 4rem;
    box-shadow: 0px 4px 4px 0px #000;

    p {
      text-align: left;
    }

    &__cookies {
      width: 26rem;
      position: absolute;
      // top: -2.4rem;
      // left: -1rem;
      top: -3.6rem;
      left: 1rem;
      transform: unset;
    }

    // &__vet {
    //   position: absolute;
    //   bottom: 2rem;
    //   z-index: 10;
    //   width: auto;
    // }

    &__content {
      max-width: 50%;
    }
  }

  @include for-giant-desktop-up {
    padding: rs(24) rs(52) rs(24) rs(280);
    grid-gap: rs(40);

    * {
      font-size: rs(16) !important;
    }

    &__cookies {
      top: rs(-36);
      left: rs(10);
      width: rs(260);
    }

    // &__vet {
    //   bottom: rs(20);
    //   width: rs(100);
    // }
  }
}
