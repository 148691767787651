
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
// Library css imports
@import "scss/lib/swiper-bundle.min.css";
@import "scss/lib/vue-select.css";

html,
body,
#app {
  height: 100%;

  // Disable pull to refresh
  overscroll-behavior-y: contain;
}

body {
  margin: 0;
  padding: 0;
  background: $color-white;
}

@include for-tablet-landscape-up {
  ::-webkit {
    &-scrollbar {
      width: 0.8rem;

      &-track {
        background: transparent;
      }

      &-thumb {
        background: $color-black;
        background: rgba(darken($color-green--primary, 50%), 0.7);

        &:hover {
          background: darken($color-green--primary, 10%);
        }
      }
    }
  }
}

@include for-giant-desktop-up {
  ::-webkit {
    &-scrollbar {
      width: rs(8);
    }
  }
}

.invisible {
  opacity: 0 !important;
  pointer-events: none !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: -1 !important;
}

.hidden--mobile {
  display: none !important;

  @include for-tablet-landscape-up {
    display: grid !important;
  }
}

.hidden {
  display: none !important;
}

a {
  color: $color-green--primary;
  transition: color $basic-transition-100, border $basic-transition-100;

  &:hover {
    color: $color-green--secondary;
  }
}

.toasted-container {
  @include for-tablet-landscape-up {
    top: $ui-padding !important;
  }

  .toasted.error {
    background-color: darken($color-orange--primary, 10%) !important;
  }

  @include for-giant-desktop-up {
    top: rs(24) !important;
  }
}

.cashback {
  a {
    font-weight: bold;
  }

  * {
    word-break: break-word;
    color: $color-text;
    @include fontSize(2.8rem, 3, 3rem);
    @include for-giant-desktop-up {
      font-size: rs(28) !important;
    }
  }
}

.announcement {
  a {
    font-weight: bold;
  }

  * {
    color: $color-text;
    @include fontSize(1.9rem, 1.9, 1.9rem);
    @include for-giant-desktop-up {
      font-size: rs(19) !important;
    }
  }
}
