.screen__quiz {
  transition: all $basic-transition-500 0.3s;
  display: flex !important;
  flex-direction: row;

  &--green {
    background: $color-green--primary;

    @include for-tablet-landscape-up {
      background: linear-gradient(
        180deg,
        $color-green--primary 22.7%,
        rgba(darken($color-green--primary, 30%), 0.5) 100%
      );
    }
  }

  &--orange {
    background: $color-orange--primary;

    @include for-tablet-landscape-up {
      background: linear-gradient(
        180deg,
        $color-orange--primary 22.7%,
        rgba(darken($color-orange--primary, 30%), 0.5) 100%
      );
    }
  }

  &--purple {
    background: $color-purple--primary;

    @include for-tablet-landscape-up {
      background: linear-gradient(
        180deg,
        $color-purple--primary 22.7%,
        rgba(darken($color-purple--primary, 30%), 0.5) 100%
      );
    }
  }

  &.info--active {
    .question__container {
      flex: 0;
      padding: 0;
      opacity: 0;

      @include for-tablet-landscape-up {
        padding: 7.2rem 7.2rem 2.4rem 7.2rem;
        flex: 2;
        opacity: 1;
      }
    }

    .question__info {
      flex: 1;
    }
  }

  &.info--available {
    .question__actions {
      flex: 1;
    }

    .question__container::after {
      height: 8.5rem;
    }
  }

  .question {
    &__container {
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      transition: flex $basic-transition-500 0.3s,
        padding $basic-transition-500 0.3s, opacity $basic-transition-500;
      flex: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 3.2rem 5.2rem 3.2rem;
      margin: 0 auto;
      grid-gap: 2.4rem;

      footer {
        display: none;

        @include for-tablet-landscape-up {
          display: flex;
        }
      }

      &__actions {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        // flex-direction: column;
        // grid-gap: 2.4rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        // position: relative;
        transition: flex $basic-transition-500 0.3s;
        padding: 2.4rem;
        flex: 0;

        .avatar__wrapper {
          display: block;
          position: absolute;
          z-index: 1;
          height: 7rem;
          @include scaleWidth(6);
          z-index: 1;
          right: 2.4rem;
          bottom: 2rem;

          transform: translateY(200%);
          animation: slide-up $bounce-transition-300 0.7s forwards;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @keyframes slide-up {
            from {
              transform: translateY(200%);
            }
            to {
              transform: translateY(0%);
            }
          }
        }
      }

      @include for-tablet-landscape-up {
        justify-content: space-between;
        padding: 7.2rem 7.2rem 2.4rem 7.2rem;

        .avatar__wrapper {
          display: none;
        }
      }

      @include for-desktop-up {
        max-width: 66%;
      }
    }

    &__title {
      @include fontSize(2.4rem, 3.6, 3.6rem, 1.16);
      color: $color-text--inverse;
      margin: 3.2rem 0 4.2rem 0;
      flex: 1;

      @include for-tablet-landscape-up {
        margin: 0 0 7.2rem 0;
      }
    }

    &__wrapper {
      width: 100%;
      flex: 3;

      padding-bottom: 2.4rem;
      margin: auto 0;
      @include scaleWidth(96);

      @include for-tablet-landscape-up {
        flex: unset;
        display: block;
        padding-bottom: unset;
      }

      .cat {
        &__wrapper {
          @include scaleWidth(45);
          z-index: -1;
          position: relative;
          margin: -5.2rem auto -7.5rem auto;

          * {
            pointer-events: none;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }

          @include for-tablet-landscape-up {
            margin: -7.5rem auto -12rem auto;
          }
        }

        //   &__stand {
        //     position: absolute;
        //     width: 70%;
        //     height: 14rem;
        //     top: 85%;
        //     opacity: 0.8;
        //     perspective: 60rem;
        //     left: 50%;
        //     transform: translateX(-50%);

        //     @include for-tablet-landscape-up {
        //       width: 35rem;
        //     }

        //     &--top-face {
        //       position: relative;
        //       opacity: 0.8;
        //       height: 10rem;
        //       width: 93%;
        //       left: 50%;
        //       top: -50%;
        //       transform: translateX(-50%) rotateX(60deg);
        //     }

        //     &--front-face {
        //       position: absolute;
        //       opacity: 0.8;
        //       height: 30rem;
        //       width: 100%;
        //     }

        //     &--green {
        //       & div:first-of-type {
        //         background: linear-gradient(
        //           165deg,
        //           rgba(darken($color-green--primary, 50%), 0.6) 0%,
        //           rgba($color-green--primary, 0.5) 25%,
        //           rgba($color-green--primary, 0.2) 35%,
        //           rgba(0, 0, 0, 0) 60%
        //         );
        //       }

        //       & div:last-of-type {
        //         background: linear-gradient(
        //           130deg,
        //           rgba(0, 0, 0, 0.5) 0%,
        //           rgba($color-green--primary, 0.5) 50%,
        //           rgba(lighten($color-green--primary, 25%), 1) 100%
        //         );
        //       }
        //     }

        //     &--orange {
        //       & div:first-of-type {
        //         background: linear-gradient(
        //           165deg,
        //           rgba(darken($color-orange--primary, 50%), 0.6) 0%,
        //           rgba($color-orange--primary, 0.5) 25%,
        //           rgba($color-orange--primary, 0.2) 35%,
        //           rgba(0, 0, 0, 0) 60%
        //         );
        //       }

        //       & div:last-of-type {
        //         background: linear-gradient(
        //           130deg,
        //           rgba(0, 0, 0, 0.5) 0%,
        //           rgba($color-orange--primary, 0.5) 50%,
        //           rgba(lighten($color-orange--primary, 15%), 1) 100%
        //         );
        //       }
        //     }

        //     &--purple {
        //       & div:first-of-type {
        //         background: linear-gradient(
        //           165deg,
        //           rgba(darken($color-purple--primary, 50%), 0.6) 0%,
        //           rgba($color-purple--primary, 0.5) 25%,
        //           rgba($color-purple--primary, 0.2) 35%,
        //           rgba(0, 0, 0, 0) 60%
        //         );
        //       }

        //       & div:last-of-type {
        //         background: linear-gradient(
        //           130deg,
        //           rgba(0, 0, 0, 0.5) 0%,
        //           rgba($color-purple--primary, 0.5) 50%,
        //           rgba(lighten($color-purple--primary, 25%), 1) 100%
        //         );
        //       }
        //     }
        //   }
      }
    }

    &__grid {
      display: grid;
      grid-template: auto / 1fr;
      grid-gap: 1rem;
      flex: 15;
      padding: 0.6rem;
      overflow-y: auto;
      position: relative;

      @include for-tablet-landscape-up {
        &.four {
          grid-template: 1fr 1fr / 1fr 1fr;
        }

        &.two {
          grid-template: 1fr / 1fr 1fr;
        }

        grid-gap: 2.4rem;
        overflow: hidden;
        flex: unset;
        position: unset;
        top: unset;
        bottom: unset;
      }

      &.has-cat {
        & .question__answer {
          min-height: unset;
          border-radius: 1.2rem;

          @include for-tablet-landscape-up {
            border-radius: 2.4rem;
            min-height: 12rem;
          }
        }
      }

      &__overlay {
        width: 105%;
        min-height: 5.2rem;
        position: sticky;
        z-index: 1;
        transform: translateX(-2.5%);

        &--top {
          margin-bottom: 1rem;
          top: 0;

          &-green {
            background: linear-gradient(
              180deg,
              rgba($color-green--primary, 1) 33%,
              rgba($color-green--primary, 0) 100%
            );
          }

          &-orange {
            background: linear-gradient(
              180deg,
              rgba(174, 201, 153, 0) 0%,
              rgba($color-orange--primary, 1) 33%,
              rgba($color-orange--primary, 0) 100%
            );
          }

          &-purple {
            background: linear-gradient(
              180deg,
              rgba($color-purple--primary, 1) 33%,
              rgba($color-purple--primary, 0) 100%
            );
          }
        }

        &--bottom {
          margin-top: -1rem;
          bottom: -1rem;

          &-green {
            background: linear-gradient(
              0deg,
              rgba($color-green--primary, 1) 33%,
              rgba($color-green--primary, 0) 100%
            );
          }

          &-orange {
            background: linear-gradient(
              0deg,
              rgba($color-orange--primary, 1) 33%,
              rgba($color-orange--primary, 0) 100%
            );
          }

          &-purple {
            background: linear-gradient(
              0deg,
              rgba($color-purple--primary, 1) 33%,
              rgba($color-purple--primary, 0) 100%
            );
          }
        }

        @include for-tablet-landscape-up {
          display: none;
        }
      }
    }

    &__answer {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 12rem;
      padding: 2rem;
      overflow: hidden;
      transition: all 0.1s linear;
      cursor: pointer;
      border-radius: 1.6rem;
      border: 0.4rem solid $color-black;

      @include for-tablet-landscape-up {
        min-width: 20rem;
        border-radius: 2.4rem;
        padding: 2.6rem;
      }

      * {
        @include fontSize(1.2rem, 1.4, 1.6rem);
        font-weight: 600;
        text-transform: uppercase;
      }

      &:hover {
        border: 0.4rem solid $color-white;
        color: $color-white;
      }

      &.wiggle {
        animation: wiggle 0.2s linear infinite;
      }

      @keyframes wiggle {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(-1deg);
        }
        50% {
          transform: rotate(0deg);
        }
        75% {
          transform: rotate(1deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }

      &--correct {
        &-selected {
          background: $color-white;
          border: 0.4rem solid $color-white;

          * {
            color: $color-text--valid;
          }

          // &:hover {
          //   border: 0.4rem solid $color-green--secondary;
          // }

          // &.green {
          //   border: 0.4rem solid $color-green--secondary;
          // }

          // &.orange {
          //   border: 0.4rem solid $color-orange--secondary;
          // }

          // &.purple {
          //   border: 0.4rem solid $color-purple--secondary;
          // }
        }

        &-noselected {
          border: 0.4rem solid $color-text;

          // &:hover {
          //   border: 0.4rem solid $color-green--secondary;
          // }

          // &.green {
          //   border: 0.4rem solid $color-green--secondary;
          // }

          // &.orange {
          //   border: 0.4rem solid $color-orange--secondary;
          // }

          // &.purple {
          //   border: 0.4rem solid $color-purple--secondary;
          // }
        }
      }

      &--wrong {
        &-selected {
          background: $color-white;
          border: 0.4rem solid $color-white;
          opacity: 0.35;

          * {
            color: $color-text--invalid;
          }

          // &:hover {
          //   border: 0.4rem solid $color-green--secondary;
          // }

          // &.green {
          //   border: 0.4rem solid $color-green--secondary;
          // }

          // &.orange {
          //   border: 0.4rem solid $color-orange--secondary;
          // }

          // &.purple {
          //   border: 0.4rem solid $color-purple--secondary;
          // }
        }

        &-noselected {
          opacity: 0.35;

          &:hover {
            border: 0.4rem solid $color-black;
          }
        }
      }
    }

    &__info {
      transition: flex $basic-transition-500 0.3s;
      flex: 0;
      position: relative !important;

      &.green {
        background: $color-green--tertiary;
      }

      &.orange {
        background: $color-orange--tertiary;
      }

      &.purple {
        background: $color-purple--tertiary;
      }
      overflow: hidden;
      position: relative;
      height: 100%;

      &__animation {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;

        * {
          stroke: $color-white;
          fill: $color-white;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -2.4rem;
          background-image: url("/assets/img/shadow.png");
          width: 100%;
          height: 2.4rem;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &__content {
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 16rem;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;

        @include for-tablet-landscape-up {
          margin-bottom: 0;
        }

        .avatar__wrapper {
          margin-top: 3.6rem;
        }

        &--inner {
          // margin: 5.2rem 4.8rem;
          width: 100%;
          padding: 5.2rem 4.8rem 16rem 4.8rem;

          h2,
          p.text {
            opacity: 0;
            transform: translateX(50%);
            animation: slide-in $basic-transition-500 0.5s forwards;
          }

          h2 {
            margin-bottom: 2rem;
          }

          a {
            color: $color-text;

            &:hover {
              color: $color-green--primary;
            }
          }
        }

        .button {
          margin-top: auto;
          margin-bottom: 5.2rem;
        }
      }

      &__actions {
        display: flex;
        flex-direction: column;
        grid-gap: 2.4rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        display: flex;
        position: fixed;
        bottom: 0;
        padding-top: 4.2rem;
        pointer-events: none;

        .button {
          // margin: 0 0 2.4rem 0;
          pointer-events: all;
          margin-bottom: 6.4rem;
        }

        @include for-tablet-landscape-up {
          position: absolute;
          bottom: 0;
          padding-top: 0;
          grid-gap: 0;
        }

        .button,
        .pagination {
          opacity: 0;
          transform: translateX(50%);
          animation: slide-in $basic-transition-500 0.8s forwards;
        }

        &-green {
          background: linear-gradient(
            180deg,
            rgba($color-green--tertiary, 0),
            rgba($color-green--tertiary, 1) 60%
          );
        }

        &-orange {
          background: linear-gradient(
            180deg,
            rgba($color-orange--tertiary, 0),
            rgba($color-orange--tertiary, 1) 60%
          );
        }

        &-purple {
          background: linear-gradient(
            180deg,
            rgba($color-purple--tertiary, 0),
            rgba($color-purple--tertiary, 1) 60%
          );
        }
      }
    }

    &__animation {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0);
      animation: fade-in 0.2s linear forwards;
    }
  }

  footer {
    margin-top: unset !important;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: all $basic-transition-500;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    opacity: 0;
  }

  .slide-enter {
    transform: translateX(100%);
  }

  .slide-leave-to {
    transform: translate(-100%, -50%);
  }

  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all $basic-transition-500;
  }

  .slide-up-enter,
  .slide-up-leave-to {
    opacity: 0;
  }

  .slide-up-enter {
    transform: translateY(100%);
  }

  .slide-up-leave-to {
    transform: translate(-50%, -100%);
  }

  @keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateX(50%);
    }

    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes fade-in {
    0% {
      background: rgba(0, 0, 0, 0);
    }

    100% {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    grid-gap: 0.8rem;

    .dot {
      height: 0.8rem;
      width: 0.8rem;
      background: $color-white;
      border-radius: 50%;
      opacity: 0.5;

      &--active {
        opacity: 1;
      }
    }
  }

  @include for-giant-desktop-up {
    .question {
      &__container {
        overflow: hidden;
        padding: rs(88) rs(68) rs(88) rs(68) !important;
        grid-gap: rs(24);
      }

      &__title {
        font-size: rs(36) !important;
        margin: rs(40) 0 rs(72) 0;
      }

      &__wrapper {
        padding-bottom: 0;

        .cat__wrapper {
          width: rs(450);
          margin: rs(-75) auto rs(-120) auto;
        }
      }

      &__grid {
        grid-gap: rs(24);

        &.has-cat {
          .question__answer {
            border-radius: rs(24);
            min-height: rs(120);
          }
        }
      }

      &__answer {
        min-height: rs(120);
        padding: rs(26);
        border-radius: rs(24);
        border: rs(4) solid $color-black;
        min-width: rs(300);

        * {
          font-size: rs(16) !important;
        }

        &:hover {
          border: rs(4) solid $color-white;
        }

        &--correct {
          &-selected {
            border: rs(4) solid $color-white;
          }

          &-noselected {
            border: rs(4) solid $color-black;
          }
        }

        &--wrong {
          &-selected {
            border: rs(4) solid $color-white;
          }
        }
      }

      &__info {
        &__content {
          .avatar__wrapper {
            margin-top: rs(36);
            width: rs(100);

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          &--inner {
            padding: rs(52) rs(48) rs(160) rs(48);

            h2 {
              margin-bottom: rs(20);
            }
          }
        }

        &__actions {
          .button {
            margin: 0 0 rs(64) 0;
          }
        }
      }
    }
  }
}
