
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          
















































































.end-slide {
  display: grid;
  grid-template: 1fr 1fr / 1fr;
  padding: 9.6rem 1.6rem 1.6rem 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    @include fontSize(3.2rem, 3.2, 3.2rem, 1);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 2.4rem;
  }

  h3 {
    @include fontSize(1.8rem, 1.8, 1.8rem, 1.5);
    font-weight: normal;
  }

  .form {
    .number-input {
      margin: 2.4rem 0 4.2rem 0;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        margin-bottom: 2.4rem;
        margin: 0 auto;
        box-shadow: inset 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
        background: #ceeab8;

        &:hover {
          box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
        }

        &::after {
          border-radius: inherit;
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          transition: all 0.1s ease-out;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
        }
      }

      a {
        color: $color-text;
        line-height: 2.4rem;
        text-decoration: none;
        border-bottom: 0.1rem solid $color-text;
        margin-top: 4.2rem;

        &:hover {
          color: #000;
          border-bottom: 0.1rem solid #000;
        }
      }
    }
  }

  .product {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include for-tablet-landscape-up {
    padding: unset;
    grid-template: 1fr / 1fr;
    place-items: center;

    h2 {
      font-size: rs(32) !important;
      margin-bottom: rs(24);
    }

    h3 {
      font-size: rs(18) !important;
      max-width: 50%;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      transform: translateX(-33%);

      .number-input {
        margin: rs(24) 0 rs(42) 0;
      }

      .actions {
        button {
          margin-bottom: rs(24);
          margin: 0 auto;
          box-shadow: inset 0 rs(10) rs(30) 0 rgba(0, 0, 0, 0.1);
          background: #ceeab8;

          &:hover {
            box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
          }

          &::after {
            border-radius: inherit;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            transition: all 0.1s ease-out;
          }
        }

        a {
          color: $color-text;
          line-height: rs(24);
          text-decoration: none;
          border-bottom: 0.1rem solid $color-text;
          font-size: rs(14) !important;
          margin-top: rs(24);

          &:hover {
            color: #000;
            border-bottom: 0.1rem solid #000;
          }
        }
      }
    }

    .product {
      position: absolute;
      display: flex;
      width: rs(400);
      right: rs(10);
      bottom: rs(20);
    }
  }
}
