
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          





























































































































































.prompt__intro {
  pointer-events: none;
  // transition: all 0.2 linear;

  * {
    color: $color-text--inverse;
    // pointer-events: all;
  }

  // .bg-overlay {
  //   background: linear-gradient(
  //     180deg,
  //     rgba(darken($color-green--primary, 30%), 0.5) 22.7%,
  //     rgba(darken($color-green--primary, 30%), 0.3) 100%
  //   );
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   opacity: 1;
  //   transition: all $basic-transition-1000;
  //   z-index: -1;
  // }

  // &.hide-bg .bg-overlay {
  //   opacity: 0;
  // }

  p.prompt__text {
    width: 90%;
    max-width: 90%;
    position: absolute;
    font-weight: bold;
    @include fontSize(4.8rem, 6.2, 6.2rem, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    // transition: all $basic-transition-1000;
    animation: slide var(--duration) cubic-bezier(0.86, 0, 0.07, 1) forwards;

    @keyframes slide {
      0% {
        transform: translate(-50%, 0%);
        opacity: 0;
      }

      20% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      70% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      100% {
        opacity: 0;
        transform: translate(-50%, -500%);
      }
    }

    // &.slide-enter-active,
    // &.slide-leave-active {
    //   transition: all $basic-transition-1000;
    // }

    // &.slide-enter,
    // &.slide-leave-to {
    //   opacity: 0;
    // }

    // &.slide-enter {
    //   transform: translate(-50%, 0%);
    // }

    // &.slide-leave-to {
    //   transform: translate(-50%, -500%);
    // }
  }

  .scroll {
    bottom: 2.4rem;
    width: 20rem;
    @include scaleWidth(20);

    display: flex;
    align-items: center;
    flex-direction: column;
    grid-gap: 1.6rem;

    .mouse {
      width: 6rem;
      @include scaleWidth(4);

      &__animation {
        // #fff
        filter: invert(100%) sepia(0%) saturate(6922%) hue-rotate(23deg)
          brightness(111%) contrast(105%);
      }
    }

    .touch {
      width: 10rem;
      @include scaleWidth(10);
    }

    @include for-tablet-landscape-up {
      bottom: 10rem;
      grid-gap: 3rem;
      @include scaleWidth(15);

      .mouse,
      .touch {
        @include scaleWidth(6);
      }
    }

    p {
      font-weight: bold;
      @include fontSize(1.6rem, 2.4, 2.4rem, 1.2);
    }

    &.fade-enter-active,
    &.fade-leave-active {
      transition: all $basic-transition-500 0.5s;
      transform: translate(-50%, 0);
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }

    &.fade-enter,
    &.fade-leave-to {
      transform: translate(-50%, 50%);
    }
  }

  @include for-giant-desktop-up {
    p.prompt__text {
      font-size: rs(62) !important;
    }

    .scroll {
      bottom: rs(100);
      width: rs(150);
      grid-gap: rs(30);

      .mouse {
        width: rs(60);
      }

      p {
        font-size: rs(24) !important;
      }
    }
  }
}
