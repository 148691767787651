.screen__intro {
  display: grid;
  grid-template: 2fr 3fr / 1fr;

  section {
    overflow: hidden;
  }

  .logo__wrapper {
    max-width: 35%;
    margin-bottom: 0.8rem;

    img {
      object-fit: contain;
    }
  }

  .intro {
    &__video {
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__text {
      overflow-y: auto;
      display: grid;
      justify-items: center;
      background: linear-gradient(180deg, #ceeab8 0%, #a0bb8b 100%);
      box-shadow: 0 -2rem 2rem rgba(0, 0, 0, 0.15);
      padding: 1.6rem;

      h3 {
        @include fontSize(3.2rem, 3.2, 3.2rem, 1);
        margin-bottom: 0.8rem;
      }

      p {
        @include fontSize(1.8rem, 1.8, 1.8rem, 1.5);
      }

      button {
        max-width: 80%;
        margin-bottom: 4.2rem;
      }
    }

    &__footer {
      margin-top: auto;
    }

    &__paws {
      bottom: 0;
      right: 0;
      position: fixed;
      pointer-events: none;

      * {
        position: absolute;
        width: 9rem;
      }

      :nth-child(1) {
        display: none;
      }

      :nth-child(2) {
        transform: scale(0.6) scaleX(-1);
        right: 0.5rem;
        bottom: 13rem;
      }

      :nth-child(3) {
        right: 3rem;
        bottom: 1.6rem;
      }
    }
  }

  @include for-tablet-landscape-up {
    grid-template: 1fr / 3fr 2fr;

    .logo__wrapper {
      max-width: 60%;
      margin-bottom: rs(32);
    }

    .intro {
      &__text {
        padding: 6.4rem 4.4rem 4.4rem 4.4rem;
        box-shadow: rs(-20) 0 rs(20) rgba(0, 0, 0, 0.15);

        h3 {
          @include fontSize(6.4rem, 6.4, 6.4rem, 1);
          margin-bottom: rs(32);
        }

        p {
          @include fontSize(2.4rem, 2.4, 2.4rem, 1.8);
        }

        button {
          max-width: unset;
        }
      }

      &__footer {
        margin-top: auto;
      }

      &__paws {
        * {
          width: rs(113);
        }

        :nth-child(1) {
          display: unset;
          transform: scale(0.5);
          right: rs(90);
          bottom: rs(240);
        }

        :nth-child(2) {
          transform: scale(0.75) scaleX(-1);
          right: rs(20);
          bottom: rs(130);
        }

        :nth-child(3) {
          right: rs(100);
          bottom: rs(16);
        }
      }
    }
  }

  @include for-giant-desktop-up {
    .intro {
      &__text {
        padding: rs(88) rs(44) rs(44) rs(44);

        h3 {
          font-size: rs(64) !important;
        }

        p {
          font-size: rs(24) !important;
        }
      }
    }
  }
}
