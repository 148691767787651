.screen__rotate {
  background: $color-black--darker;

  .img__wrapper {
    @include scaleWidth(15);
    margin: 2rem auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    @include fontSize(1.4rem, 1.8, 1.8rem);
    color: $color-text--inverse;
    text-transform: uppercase;
    font-weight: 600;

    &.resolution {
      max-width: 60%;
      margin: 0 auto;
    }
  }
}
