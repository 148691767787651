
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          

















































.button {
  box-sizing: border-box;
  background: $color-black;
  color: $color-white;
  border: none;
  border-radius: 5.2rem;
  min-width: 10rem;
  padding: 1.2rem 2.6rem;
  transition: background-color 0.2s linear, box-shadow 0.2s linear;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    @include fontSize(1.6rem, 1.6, 1.6rem);
    text-transform: uppercase;
    font-weight: 600;
  }

  &:hover {
    background: darken($color-green--primary, 15%);
    cursor: pointer;

    .arrow {
      transform: translateX(10%);
    }
  }

  &--white {
    background: $color-white;

    span {
      color: $color-text;
    }

    &:hover {
      background: darken($color-white, 5%);
      cursor: pointer;
    }
  }

  &--outline {
    background: transparent;
    border: 0.2rem solid $color-white;
    padding: 1.1rem 2.6rem;

    span {
      color: $color-white;
    }

    &:hover {
      background: $color-white;
      border: 0.2rem solid $color-white;
      cursor: pointer;

      span {
        color: $color-text;
      }
    }
  }

  &--shadow {
    box-shadow: 0 0.2rem 0.6rem rgba(0, 0, 0, 0.25);

    &:hover {
      box-shadow: 0 0.4rem 1.2rem rgba(0, 0, 0, 0.25);
    }
  }

  &--margin {
    margin: 2rem;
  }

  &--small {
    height: 3.6rem;
  }

  &--arrow {
    padding: 1.3rem 6rem 1.3rem 2.6rem;
  }

  .arrow {
    @include scaleWidth(4); // 4 rem
    transition: transform 0.2s linear;
    position: absolute;
    right: 1rem;

    &__black {
      // #273841
      filter: invert(14%) sepia(12%) saturate(1755%) hue-rotate(157deg)
        brightness(94%) contrast(81%);
    }
    &__green {
      // #aec999
      filter: invert(75%) sepia(20%) saturate(397%) hue-rotate(51deg)
        brightness(100%) contrast(90%);
    }
    &__orange {
      // #f6a372
      filter: invert(69%) sepia(18%) saturate(1153%) hue-rotate(334deg)
        brightness(102%) contrast(93%);
    }
    &__blue {
      // #abcfdc
      filter: invert(89%) sepia(14%) saturate(681%) hue-rotate(163deg)
        brightness(91%) contrast(88%);
    }
    &__purple {
      // #bca0d1
      filter: invert(69%) sepia(13%) saturate(733%) hue-rotate(231deg)
        brightness(99%) contrast(82%);
    }
    &__grey {
      // #c0c0c0
      filter: invert(76%) sepia(53%) saturate(0%) hue-rotate(172deg)
        brightness(93%) contrast(90%);
    }
  }

  &--game {
    padding: 1.6rem 5.6rem;
    position: relative;
    transition: top 0.2s linear;
    background: transparent;
    display: inline;
    margin-top: 3.2rem;

    span {
      @include fontSize(2rem, 2, 2rem, 1);
      font-weight: bold;
      z-index: 3;
      position: relative;
      top: 0;
      transition: top 0.1s ease-out;
    }

    &::before,
    &::after {
      border-radius: 5.2rem;
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      transition: all 0.1s ease-out;
    }

    &::before {
      z-index: 2;
      background: $color-orange--primary;
      background: linear-gradient(
        180deg,
        #da6127 0%,
        $color-orange--primary 100%
      );
      top: 0;
    }

    &::after {
      z-index: 1;
      background: #823811;
      box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
      top: 0.6rem;
    }

    &:hover::before {
      box-shadow: inset 0 2rem 5rem 0 #f47d43;
    }

    &:active {
      background: transparent;

      span {
        top: 0.6rem;
      }

      &::before {
        box-shadow: inset 0 2rem 5rem 0 #f47d43;
        top: 0.6rem;
        bottom: unset;
      }
    }

    @include for-tablet-landscape-up {
      margin-top: rs(50);
      padding: rs(20) rs(56) !important;

      span {
        font-weight: 600;
        font-size: rs(24);
      }

      &::before,
      &::after {
        border-radius: rs(52);
      }

      &::after {
        box-shadow: 0 rs(4) rs(4) rgba(0, 0, 0, 0.25);
        top: rs(6);
      }

      &:hover::before {
        box-shadow: inset 0 rs(20) rs(50) 0 #f47d43;
      }

      &:active {
        span {
          top: rs(6);
        }

        &::before {
          top: rs(6);
        }
      }
    }
  }

  @include for-tablet-landscape-up {
    padding: rs(12) rs(26);
    border-radius: rs(52);
    min-width: rs(100);

    span {
      font-size: rs(16) !important;
    }

    &--outline {
      border: rs(2) solid $color-white;
      padding: rs(11) rs(26);

      &:hover {
        border: rs(2) solid $color-white;
      }
    }

    &--margin {
      margin: rs(20);
    }

    &--small {
      height: rs(36);
    }

    &--arrow {
      padding: rs(13) rs(60) rs(13) rs(26);
    }

    .arrow {
      width: rs(40);
      right: rs(10);
    }
  }
}
