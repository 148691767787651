
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          































































.prompt__times-up {
  overflow: hidden;
  background: rgba(#0079b9, 0.5);
  display: grid;
  place-content: center;

  *:not(.button) {
    color: $color-text--inverse;
    pointer-events: all;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    width: 90%;

    h2 {
      @include fontSize(3.6rem, 4.6, 4.6rem, 1.2);
      margin-bottom: 2.4rem;
      font-weight: lighter;

      span {
        @include fontSize(3.6rem, 4.6, 4.6rem, 1.2);
        color: $color-orange--primary !important;
      }
    }

    p {
      @include fontSize(1.8rem, 2.8, 2.8rem, 1.28);
    }

    @include for-tablet-landscape-up {
      max-width: 33%;
    }
  }

  &__prompt {
    position: absolute;
    font-weight: 600;
    @include fontSize(4.8rem, 6.2, 6.2rem, 1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    place-content: center;
    text-transform: uppercase;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all $basic-transition-1000;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-leave-to {
    // transform: scale(10);
  }

  @include for-giant-desktop-up {
    &__text {
      h2 {
        font-size: rs(46) !important;
        margin-bottom: rs(24);

        span {
          font-size: rs(46) !important;
        }
      }

      p {
        font-size: rs(28) !important;
      }
    }

    &__prompt {
      font-size: rs(62) !important;
    }
  }
}
