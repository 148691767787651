
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          




































.number-input {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    margin: 0;
    font-weight: 600;
    @include fontSize(1.2rem, 1.2, 1.2rem);
    color: $color-text--inverse;
    text-transform: uppercase;
  }

  input {
    @include fontSize(5.6rem, 5.6, 5.6rem);
    width: 20rem;
    min-width: 20rem;
    @include scaleWidth(200);
    height: 8rem;
    min-height: 8rem;
    @include scaleHeight(80);

    position: relative;
    -webkit-appearance: none;
    margin: 0;
    background: #ffffff;
    border-radius: 0.8rem;
    border: none;
    padding: 0.5rem 1.2rem;
    margin-top: 0.7rem;
    font-weight: 600;
    text-align: center;

    &.invalid {
      box-shadow: 0 0 0 0.6rem $color-text--invalid;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.6rem $color-blue--secondary;
    }

    &::placeholder {
      opacity: 0.3;
    }
  }

  .error {
    font-weight: 600;
    @include fontSize(1.2rem, 1.2, 1.2rem);
    margin: 1rem 0 0 0;
    color: $color-text--invalid;
  }

  .slide-down-enter-active,
  .slide-down-leave-active {
    transition: all $basic-transition-300;
  }

  .slide-down-enter,
  .slide-down-leave-to {
    opacity: 0;
    transform: translateY(-50%);
  }

  @include for-tablet-landscape-up {
    label {
      font-size: rs(12) !important;
    }

    input {
      border-radius: rs(8);
      padding: rs(12) rs(24);
      margin-top: rs(7);

      font-size: rs(56) !important;
      width: rs(200);
      min-width: rs(200);
      height: rs(80);
      min-height: rs(80);

      &.invalid {
        box-shadow: 0 0 0 rs(6) $color-text--invalid;
      }

      &:focus {
        box-shadow: 0 0 0 rs(6) $color-blue--secondary;
      }
    }

    .error {
      font-size: rs(12) !important;
      margin: rs(10) 0 0 0;
    }
  }
}
