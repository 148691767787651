
            @import "@/scss/common/_breakpoints.scss";
            @import "@/scss/common/_mixins.scss";
            @import "@/scss/common/_vars.scss";
            @import "@/scss/common/_reset.scss";
            @import "@/scss/common/_fonts.scss";
          




























































































































































































































.end-slide {
  display: grid;
  grid-template: 1fr 1fr / 1fr;
  padding: 9.6rem 1.6rem 1.6rem 1.6rem;
  overflow-y: auto;
  overflow-x: hidden;

  h2 {
    @include fontSize(3.2rem, 3.2, 3.2rem, 1);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 2.4rem;
  }

  h3 {
    @include fontSize(1.8rem, 1.8, 1.8rem, 1.5);
    font-weight: normal;
  }

  .prize {
    position: relative;
    height: 70vh;

    .swiper {
      margin-top: 4.2rem;
      width: 100%;
      position: absolute;

      * {
        width: 100%;
      }

      p {
        @include fontSize(1.8rem, 1.8, 1.8rem, 1.5);
        text-transform: uppercase;
        color: $color-text--inverse;
        font-weight: 600;
      }
    }
  }

  .form {
    margin-bottom: 12rem;

    * {
      text-align: left;
    }

    form {
      position: relative;
      display: grid;
      grid-template: auto / 1fr;
      grid-gap: 4.2rem;
      margin-bottom: 4.2rem;

      h3 {
        @include fontSize(2.8rem, 3, 3rem);
        font-weight: lighter;
        margin: 0;
      }
    }

    .divider {
      display: block;
      height: 0.2rem;
      width: 100%;
      background: $color-blue--secondary;
      opacity: 0.1;

      @include for-tablet-landscape-up {
        display: none;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 2.4rem;
      width: 100%;
      margin: 0 auto;
      box-shadow: inset 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
      background: #ceeab8;

      &:hover {
        box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
      }

      &::after {
        border-radius: inherit;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        transition: all 0.1s ease-out;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      }
    }

    a {
      color: $color-text;
      line-height: 2.4rem;
      text-decoration: none;
      border-bottom: 0.1rem solid $color-text;
      margin-top: 4.2rem;

      &:hover {
        color: #000;
        border-bottom: 0.1rem solid #000;
      }
    }
  }

  @include for-tablet-landscape-up {
    grid-template: 1fr / 1fr 1fr;
    padding: unset;

    h2 {
      font-size: rs(32) !important;
      margin-bottom: rs(24);
    }

    h3 {
      font-size: rs(18) !important;
    }

    .prize {
      padding: rs(46) 0;
      margin: auto 0;

      .swiper {
        width: 65%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: rs(42);

        p {
          font-size: rs(18) !important;
        }
      }
    }

    .form {
      padding: unset;
      margin: auto 0;

      * {
        max-width: rs(330);
      }

      form {
        grid-gap: rs(24);
        margin-bottom: rs(24);

        h3 {
          font-size: rs(21) !important;
        }

        .select-input {
          margin-top: rs(5) !important;
        }

        .check-input {
          margin-top: rs(24) !important;

          * {
            font-size: rs(14) !important;
          }
        }
      }

      a {
        font-size: rs(14) !important;
        margin-top: rs(24);
      }
    }

    // .actions {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;

    //   button {
    //     margin-bottom: 2.4rem;
    //     width: 100%;
    //     margin: 0 auto;
    //     box-shadow: inset 0 1rem 3rem 0 rgba(0, 0, 0, 0.1);
    //     background: #ceeab8;

    //     &:hover {
    //       box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    //     }

    //     &::after {
    //       border-radius: inherit;
    //       content: "";
    //       position: absolute;
    //       left: 0;
    //       right: 0;
    //       bottom: 0;
    //       height: 100%;
    //       transition: all 0.1s ease-out;
    //       box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    //     }
    //   }

    //   a {
    //     color: $color-text;
    //     line-height: 2.4rem;
    //     text-decoration: none;
    //     border-bottom: 0.1rem solid $color-text;
    //     margin-top: 4.2rem;

    //     &:hover {
    //       color: #000;
    //       border-bottom: 0.1rem solid #000;
    //     }
    //   }
    // }
  }
}
